import store, { userAtom } from '~/auth/store';
import reject from './reject';
import { IUser } from '~/types';

export default async function user(): Promise<IUser | void | null> {
  const user = store.get(userAtom);
  try {
    return user;
  } catch (error) {
    return await reject();
  }
}
