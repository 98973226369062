export const constants = {
  recipe: {
    cuts: 0,
    min_final_product_length: 0,
    max_final_product_length: 9000,
    min_cuts: 0,
    max_cuts: 20,
    min_order_volume: 0,
    min_ramp_up_time: 0,
    ramp_up_time: 8,
    max_ramp_up_time: 20,
    min_stretcher_scrap: 0,
    stretcher_scrap: 1.5,
    max_stretcher_scrap: 5,
  },
  extrusion: {
    default_defect: '0',
  },
};
