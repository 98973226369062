import responseToaster from '~/lib/response-toaster';
import { StatusCodes } from 'http-status-codes';
import { redirect } from 'react-router-dom';
import ApiRequest from '~/lib/api-request';
import reject from './reject';
import setToken from './set-token';
import { IUser } from '~/types';

export default async function login(request: { [k: string]: FormDataEntryValue | null }) {
  return await new ApiRequest<
    {
      access_token: string;
      refresh_token: string;
    } & IUser
  >({
    endpoint: `/auth/login`,
    data: request,
    cache: false,
    refreshToken: false,
    callback: (response) => {
      if (response.status === StatusCodes.FORBIDDEN) {
        reject();
        responseToaster(response);
        return response;
      }
      if (response.status === StatusCodes.UNAUTHORIZED) {
        reject();
        responseToaster(response);
        return response;
      }

      if (response.data?.access_token) {
        setToken(response);
        return redirect(`/`);
      }

      return response;
    },
  }).post();
}
