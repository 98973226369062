import { IResponse } from '~/lib/api-request';
import { useLoaderData } from 'react-router-dom';

interface LoaderProps {
  <T extends (...args: any[]) => Promise<any>>(): Awaited<
    ReturnType<T> extends IResponse<infer U> ? U : ReturnType<T>
  >;
}

export const useLoader: LoaderProps = () => {
  const useLoaderDataRef = useLoaderData as LoaderProps;
  return useLoaderDataRef();
};

export default useLoader;
