import responseToaster from '~/lib/response-toaster';
import { StatusCodes } from 'http-status-codes';
import { redirect } from 'react-router-dom';
import ApiRequest from '~/lib/api-request';

export default async function resetPassword(request: { [k: string]: FormDataEntryValue | null }) {
  return await new ApiRequest({
    endpoint: `/auth/reset-password`,
    data: request,
    cache: false,
    refreshToken: false,
    callback: (response) => {
      responseToaster(response);
      if (response.status === StatusCodes.OK) {
        redirect('/login');
      }
      return response;
    },
  }).post();
}
