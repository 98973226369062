export enum RecipeType {
  ORDER = 'order',
  OFFER = 'offer',
  TRIAL = 'trial',
}

interface AbstractEntity {
  id: string;
  status: number;
  created_at: string;
  updated_at: string;
  version: number;
  created_by: string;
  updated_by: string;
}
interface TenancyEntity extends AbstractEntity {
  name: string;
  users: UserEntity[];
}
interface AlloyEntity extends TenancyEntity {
  title: string;
  description: string;
  tensile_strength: number;
  min_billet_temp: number;
  max_billet_temp: number;
  type: number;
  recipes: RecipeEntity[];
}
interface BookEntity extends AbstractEntity {
  title: string;
  description: string;
  file_name: string;
  url: string;
  size: number;
  type: string;
}
interface CalculationEntity extends TenancyEntity {
  first_billet_length: number;
  normal_billet_length: number;
  last_billet_length: number;
  billets_per_order: number;
  extrusion_length: number;
  lengths_per_billet: number;
  start_up_ram_speed: number;
  normal_ram_speed: number;
  normal_product_speed: number;
  billets_per_hour: number;
  productivity_per_hour: number;
  net_productivity_per_hour: number;
  scrap: number;
  time_to_finish_order: number;
  seconds_by_billet: number;
  first_billet_temperature: number;
  second_billet_temperature: number;
  normal_billet_temperature: number;
  container_temperature: number;
  die_temperature_at_the_press: number;
  exit_temperature_press_mouth: number;
  contact_time: number;
  extrusion_ratio: number;
}
interface FileEntity extends AbstractEntity {
  url: string;
  size: string;
  name: string;
  originalname: string;
  type: string;
  parent_id: string;
}
interface CorrectionEntity extends TenancyEntity {
  nitriding: Date;
  notes: string;
  extrusion: ExtrusionEntity;
  corrector: UserEntity;
  mail_sent: boolean;
}
interface CustomerEntity extends TenancyEntity {
  title: string;
  description: string;
  address: string;
  country: string;
  phone: string;
  email: string;
  website: string;
  industry: string;
  drawings: DrawingEntity[];
}
interface DieEntity extends TenancyEntity {
  title: string;
  description: string;
  cavities: number;
  type: string;
  annual_demand: number;
  recipes: RecipeEntity[];
  drawing: DrawingEntity;
  vendor: VendorEntity;
  drawing_id: string;
  vendor_id: string;
  cost: number;
}
interface DrawingEntity extends TenancyEntity {
  title: string;
  description: string;
  area: number;
  outside_perimeter: number;
  inside_perimeter: number;
  circumference: number; // DU
  thickness_min: number; // minimum wall thickness
  complexity: string;
  mandrels: number;
  customer: CustomerEntity;
  dies: DieEntity[];
  customer_id: string;
}
interface ExtrusionEntity extends TenancyEntity {
  title: string;
  description: string;
  billet_length: number;
  billet_qty: number;
  weight: number;
  meter: number;
  notes: string;
  defect: number;
  correction: CorrectionEntity;
  recipe: RecipeEntity;
  recipe_id: string;
  mail_sent: boolean;
}

interface PressEntity extends TenancyEntity {
  title: string;
  description: string;
  force: number; // Force in tons, effective 98%
  max_ram_speed: number; // Max ram speed in mm/sec
  max_puller_speed: number; // Max puller speed in m/min
  puller_position: number; // Closest puller position to die exit
  run_out_table_length: number; // Run out table length in m
  max_stretcher_length: number; // Max stretcher length in m
  min_stretcher_length: number; // Min stretcher length in m
  container_diameter: number; // Container diameter in mm
  max_shearable_length: number; // Container diameter in mm
  min_shearable_length: number; // Container diameter in mm
  billet_diameter: number; // Billet diameter in mm
  container_length: number; // Container length in mm
  dead_cycle_time: number; // sec
  die_change_time: number; // sec
  max_kg_hour_billet_oven: number; // MAXIMUM KGS/HOUR BILLET OVEN
  technology: number; // 1: direct, 2:indirect
  billet_type: number; // 1: cut, 2:shear
  recipes: RecipeEntity[];
}
interface RecipeEntity extends TenancyEntity {
  title: string;
  description: string;
  temper: string;
  surface: string;
  final_product_length: number;
  cuts: number;
  order_volume: number;
  ramp_up_time: number; // sec
  stretcher_scrap: number; // sec
  die: DieEntity;
  press: PressEntity;
  alloy: AlloyEntity;
  die_id: string;
  press_id: string;
  alloy_id: string;
  calculation: CalculationEntity;
  extrusions: ExtrusionEntity[];
  mail_sent: boolean;
  changed: boolean;
  type: RecipeType;
}
interface TenantEntity extends AbstractEntity {
  name: string;
  users: UserEntity[];
}
interface UserEntity extends AbstractEntity {
  name: string;
  email: string;
  role: number;
  password: string;
  correction: UserEntity[];
  tenants?: TenantEntity[];
  active_tenant: string;
  refresh_token: string;
}
interface VendorEntity extends TenancyEntity {
  title: string;
  description: string;
  address: string;
  country: string;
  phone: string;
  email: string;
  website: string;
  dies: DieEntity[];
}

export interface IAbstract extends AbstractEntity {}
export interface ITenant extends TenancyEntity, IAbstract {}
export interface IFile extends FileEntity, IAbstract {}
export interface IBook extends BookEntity, IAbstract {}
export interface IAlloy extends AlloyEntity, ITenant {
  files: IFile[];
}
export interface ICalculation extends CalculationEntity, ITenant {}
export interface ICorrection extends CorrectionEntity, ITenant {
  files: IFile[];
}
export interface ICustomer extends CustomerEntity, ITenant {}
export interface IDie extends DieEntity, ITenant {
  files: IFile[];
  drawing: IDrawing;
  vendor: IVendor;
}
export interface IDrawing extends DrawingEntity, ITenant {
  files: IFile[];
}
export interface IPress extends PressEntity, ITenant {
  files: IFile[];
}
export interface IExtrusion extends ExtrusionEntity, ITenant {
  files: IFile[];
  recipe: IRecipe;
}
export interface IRecipe extends RecipeEntity, ITenant {
  calculation: ICalculation;
  drawing: IDrawing;
  die: IDie;
  press: IPress;
  alloy: IAlloy;
}
export interface IVendor extends VendorEntity, ITenant {}
export interface IUser extends UserEntity, ITenant {
  tenants: ITenant[];
  menu: string[];
}
export interface IMeta {
  page: string;
  limit: string;
  total: string;
  last_page: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}
export interface ICountry extends IAbstract {
  title: string;
  description: string;
}
export interface IConstant extends IAbstract {
  title: string;
  description: string;
  type: string;
  value: number | string;
}
export interface IOverview extends IAbstract {
  title: string;
  weight: number;
  meter: number;
  month: number;
  year: number;
}
export interface IDashboard extends IAbstract {
  press: IOverview[];
  die: IOverview[];
}
export interface IRecipeReport {
  recipe_id: string;
  recipe_title: string;
  press_title: string;
  die_title: string;
  calculation_normal_ram_speed: number;
  press_max_ram_speed: number;
}

export interface IIndustry {
  industry: string;
}

export interface ICorrectionReport {
  extrusion_title: string;
  die_id: string;
  die_title: string;
  correction_id: string;
  correction_date: string;
  nitriding_date: string;
  correction_notes: string;
  corrector_name: string;
  produced_m: number;
  produced_kg: number;
}

export const Roles = {
  1: 'User',
  2: 'Manager',
  3: 'Super Admin',
} as const;

export const AlloyTypes = {
  1: 'AlMgSi',
  2: 'AlZnMg',
  3: 'AlZnMgCu',
  4: 'AlCuMg',
  5: 'AlMg',
  6: 'AlMn',
  7: 'CPAL',
} as const;
export const PressTechnology = {
  1: 'Direct',
  2: 'Indirect',
} as const;

export const PressBilletType = {
  1: 'Cut',
  2: 'Shear',
} as const;
